var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-form-group",
    { attrs: { invalid: _vm.error !== null } },
    [
      _c(
        "gov-label",
        { staticClass: "govuk-!-font-weight-bold", attrs: { for: _vm.id } },
        [
          _vm._t("label", function() {
            return [_vm._v(_vm._s(_vm.label))]
          })
        ],
        2
      ),
      _vm._t("hint", function() {
        return [
          _vm.hint
            ? _c("gov-hint", {
                attrs: { for: _vm.id },
                domProps: { textContent: _vm._s(_vm.hint) }
              })
            : _vm._e()
        ]
      }),
      _c(
        "ck-character-count-group",
        [
          _c("gov-textarea", {
            attrs: {
              value: _vm.value,
              id: _vm.id,
              name: _vm.id,
              rows: _vm.rows,
              maxlength: _vm.maxlength
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          }),
          _vm.maxlength
            ? _c("ck-character-count", {
                attrs: { count: _vm.value.length, "max-length": _vm.maxlength }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._t("after-input"),
      _vm.error !== null
        ? _c("gov-error-message", {
            attrs: { for: _vm.id },
            domProps: { textContent: _vm._s(_vm.error) }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }